import {
  DatePicker,
  Select,
  ConfigProvider,
  Divider,
  Space,
  Input,
  Button,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import moment from "moment";

const QuickInvoice = () => {
  let navigate = useNavigate();
  let accessToken = JSON.parse(localStorage.getItem("userDetails"));
  let prefilledData = JSON.parse(localStorage.getItem("preferences"));
  let prefillExpiryDate = "";
  // Calculate the expiry duration in milliseconds
  const expiryDurationInMillis =
    prefilledData === null
      ? accessToken?.client?.preferences?.defaultInvoiceExpiryDuration
      : prefilledData?.defaultInvoiceExpiryDuration;
  // Convert milliseconds to hours
  const hours = expiryDurationInMillis / 3600000;

  if (!isNaN(hours) && isFinite(hours)) {
    const today = new Date();
    today.setHours(today.getHours() + hours);
    const formattedDate = today.toISOString().split("T")[0];
    prefillExpiryDate = formattedDate;
  } else {
    console.error("Invalid expiry duration:", hours);
  }

  // ID
  const [ID, setID] = useState("");
  // ID Main
  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [MobileNo, setMobileNo] = useState("");
  const [SMSVia, setSMSVia] = useState("");
  const [InvoiceValue, setInvoiceValue] = useState("");
  const [InvoiceRef, setInvoiceRef] = useState("");
  const [CustomerRef, setCustomerRef] = useState("");
  const [Comment, setComment] = useState("");
  const [TermsCondition, setTermsCondition] = useState("");
  const [ExpireDate, setExpireDate] = useState(prefillExpiryDate);
  // Loaders
  const [SearchLoader, setSearchLoader] = useState(false);
  const [Loader, setLoader] = useState(false);

  // Error handlers
  const [NotFound, setNotFound] = useState(false);
  const [InputError, setInputError] = useState("");

  useEffect(() => {
    const getCustomerData = () => {
      setNotFound(false);
      if (MobileNo.length >= 10) {
        setSearchLoader(true);
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        fetch(
          process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL +
            `customers?mobileNumber=${MobileNo}`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            // console.log(result);
            if (result.customers.length > 0) {
              setID(result.customers[0].id);
              setName(result.customers[0].name);
              setEmail(result.customers[0].email);
              setMobileNo(result.customers[0].mobileNumber);
              setSearchLoader(false);
            } else {
              setSearchLoader(false);
              setNotFound(true);
              setID("");
              setName("");
              setEmail("");
              // setMobileNo("");
            }
          })
          .catch((error) => console.log("error", error));
      }
    };
    getCustomerData();
  }, [accessToken.accessToken, MobileNo]);

  const handleExpireChange = (date, dateString) => {
    setExpireDate(dateString);
  };

  const handleCreateInvoice = () => {
    setInputError("");
    if (
      Name === "" ||
      Email === "" ||
      MobileNo === "" ||
      InvoiceValue === "" ||
      ExpireDate === ""
    ) {
      // setInputError("please fill all the required fields");
      swal("Failed!", "Please fill all the required fields", "error");
    } else {
      setLoader(true);
      let myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

      let raw = JSON.stringify({
        amount: InvoiceValue,
        invoiceRef: InvoiceRef,
        expiryDate: ExpireDate,
        comments: Comment,
        customerId: ID,
        invoiceItems: [],
      });

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL + "invoices/new",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          if (result.message === "Successfully Created") {
            setLoader(false);
            swal("Created!", result.message, "success");
            navigate("/invoice-list");
          } else {
            swal("Failed!", result.message, "error");
            setLoader(false);
          }
        })
        .catch((error) => console.log("error", error));
    }
  };

  const [CustomerData, setCustomerData] = useState([]);
  const getCustomerData = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    await fetch(
      process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL + `customers`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        setCustomerData(result.customers);
      })
      .catch((error) => console.log("error", error));
  };
  useEffect(() => {
    getCustomerData();
  }, [accessToken.accessToken]);

  const Users = () => {
    return CustomerData.map((el) => ({
      value: el.mobileNumber,
      label: (
        <div className="flex justify-between items-center">
          <div className="flex gap-2 items-center">
            <i className="bi bi-person-bounding-box text-[#0aa1dd]"></i>
            <p className="font-semibold">{el.name}</p>
          </div>
          <div>{el.mobileNumber}</div>
        </div>
      ),
    }));
  };

  const filterOption = (input, option) => {
    const label = (option?.value ?? "").toLowerCase();
    const inputValue = input.toLowerCase();
    return label.includes(inputValue);
  };

  const [cusName, setCusName] = useState("");
  const [cusEmail, setCusEmail] = useState("");
  const [cusMobile, setCusMobile] = useState("");
  const [Loading, setLoading] = useState(false);
  const [Error, setError] = useState(false);

  const CreateCustomer = () => {
    if (cusName === "") {
      setError("name is required");
    } else if (cusMobile === "") {
      setError("mobile is required");
    } else if (cusMobile.length > 8) {
      setError("mobile number must be 8 digits only.");
    } else if (!/^[0-9]{8}$/.test(cusMobile)) {
      setError("Invalid mobile number");
    } else {
      setLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

      var raw = JSON.stringify({
        name: cusName,
        email: cusEmail,
        mobileNumber: cusMobile,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL + "customers/new",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          if (result.message === "Successfully Created") {
            getCustomerData();
            setLoading(false);
            setCusName("");
            setCusEmail("");
            setCusMobile("");
          } else {
            swal("Denied!", `${result.message}`, "error");
            setLoading(false);
          }
        })
        .catch((error) => console.log("error", error));
    }
  };

  return (
    <div className="flex flex-col gap-6 animatedView">
      {InputError && (
        <div className="text-sm text-red-500 animatedView">{InputError}</div>
      )}
      {/* First Row */}
      <div className="flex flex-col md:flex-col lg:flex-row gap-4">
        {/* Mobile */}
        <div className="w-full lg:w-1/2">
          <label
            htmlFor="name"
            className="text-[#545454] text-[14px] font-semibold"
          >
            Mobile Number <span className="text-red-500">*</span>
          </label>
          <div className="flex items-center relative">
            <div className="bg-white border-r rounded-l-lg">
              <select
                id="countries"
                className="bg-white text-gray-900 text-sm rounded-lg block py-[11.9px] px-[15px]"
              >
                <option className="p-1" defaultValue>
                  +965
                </option>
                <option className="p-1" value="US">
                  +989
                </option>
                <option className="p-1" value="CA">
                  +91
                </option>
              </select>
            </div>
            <ConfigProvider
              theme={{
                token: {
                  controlHeight: 36,
                  borderRadius: "0px 6px 6px 0px",
                  colorBorder: "rgba(255,255,255,0)",
                  colorPrimaryHover: "#0aa1dd",
                  lineWidth: 0,
                  fontSize: 12,
                },
              }}
            >
              <Select
                showSearch
                size="large"
                placeholder="Select a person"
                className="w-full"
                optionFilterProp="children"
                filterOption={filterOption}
                options={Users()}
                onChange={(value) => setMobileNo(value)}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider
                      style={{
                        margin: "8px 0",
                      }}
                    />
                    <Space
                      style={{
                        padding: "0 8px 4px",
                      }}
                    >
                      <input
                        type="text"
                        name="name"
                        id=""
                        className={`${
                          Error && Error.length > 0
                            ? "border border-red-500 placeholder:text-red-500"
                            : "border border-gray-200"
                        } border rounded-lg p-2 w-full`}
                        value={cusName}
                        onChange={(e) => setCusName(e.target.value)}
                        placeholder="Enter name"
                      />
                      <input
                        type="text"
                        name="name"
                        id=""
                        className={`${
                          Error && Error.length > 0
                            ? "border border-red-500 placeholder:text-red-500"
                            : "border border-gray-200"
                        } border rounded-lg p-2 w-full`}
                        value={cusMobile}
                        onChange={(e) => setCusMobile(e.target.value)}
                        placeholder="Enter mobile"
                      />
                      <input
                        type="text"
                        name="name"
                        id=""
                        className={`${
                          Error && Error.length > 0
                            ? "border border-red-500 placeholder:text-red-500"
                            : "border border-gray-200"
                        } border rounded-lg p-2 w-full`}
                        value={cusEmail}
                        onChange={(e) => setCusEmail(e.target.value)}
                        placeholder="Enter email"
                      />
                      <button
                        className="flex items-center gap-2"
                        onClick={CreateCustomer}
                      >
                        {Loading ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          <>
                            <PlusOutlined />
                            <span>Add</span>
                          </>
                        )}
                      </button>
                    </Space>
                  </>
                )}
              />
            </ConfigProvider>
            {SearchLoader && (
              <div className="absolute right-4 top-4">
                <Spinner animation="border" variant="primary" size="sm" />
              </div>
            )}
          </div>
          <p className="text-xs tracking-wide py-1 text-[#545454]">
            {NotFound === true ? (
              <span className="text-red-500">Not Found</span>
            ) : (
              "please enter full number to get customer data."
            )}
          </p>
        </div>
        {/* Via */}
        <div className="w-full lg:w-1/4">
          <div className="flex flex-col">
            <label
              htmlFor="name"
              className="text-[#545454] text-[14px] font-semibold"
            >
              Send Invoice via
            </label>
            <select
              id="countries"
              name="smsVia"
              value={SMSVia}
              onChange={(e) => setSMSVia(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-[11px] px-[15px]"
            >
              <option className="p-1" defaultValue value="SMS">
                SMS
              </option>
              <option className="p-1" value="WhatsApp">
                WhatsApp
              </option>
            </select>
          </div>
        </div>
        {/* Expire */}
        <div className="w-full lg:w-1/4">
          <div className="flex flex-col">
            <label
              htmlFor="name"
              className="text-[#545454] text-[14px] font-semibold"
            >
              Expire Date <span className="text-red-500">*</span>
            </label>
            <DatePicker
              onChange={handleExpireChange}
              className="w-full px-3 placeholder:text-black placeholder:font-semibold border-none"
              style={{ padding: 9 }}
              placeholder="dd/mm/yyyy 11:12:00"
              value={moment(ExpireDate)._i !== NaN && moment(ExpireDate)}
            />
          </div>
        </div>
      </div>
      {/* Second Row */}
      <div className="flex flex-col md:flex-col lg:flex-row gap-4">
        {/* Name */}
        <div className="w-full lg:w-1/2 flex flex-col">
          <label
            htmlFor="name"
            className="text-[#545454] text-[14px] font-semibold"
          >
            Customer Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="name"
            id=""
            className="text-[14px] py-[11px] px-[15px] rounded-lg outline-none w-full"
            value={Name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter your customer name"
          />
        </div>
        {/* Invoice Value */}
        <div className="w-full lg:w-1/4 flex flex-col">
          {/* Second input taking 25% space */}
          <label
            htmlFor="name"
            className="text-[#545454] text-[14px] font-semibold"
          >
            Invoice Value <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="invoiceValue"
            id=""
            className="text-[14px] py-[11px] px-[15px] rounded-lg outline-none w-full"
            value={InvoiceValue}
            onChange={(e) => setInvoiceValue(e.target.value)}
            placeholder="Kuwait [KD]"
          />
        </div>
        {/* Invoice Ref */}
        <div className="w-full lg:w-1/4 flex flex-col">
          {/* Second input taking 25% space */}
          <label
            htmlFor="name"
            className="text-[#545454] text-[14px] font-semibold"
          >
            Invoice Ref
          </label>
          <input
            type="text"
            name="invoiceRef"
            id=""
            className="text-[14px] py-[11px] px-[15px] rounded-lg outline-none w-full"
            value={InvoiceRef}
            onChange={(e) => setInvoiceRef(e.target.value)}
          />
        </div>
      </div>
      {/* Third Row */}
      <div className="flex flex-col md:flex-col lg:flex-row gap-4 relative">
        {/* Email */}
        <div className="w-full lg:w-1/2 flex flex-col">
          <label
            htmlFor="name"
            className="text-[#545454] text-[14px] font-semibold"
          >
            Customer Email
          </label>
          <input
            type="text"
            name="email"
            id=""
            className="text-[14px] py-[11px] px-[15px] rounded-lg outline-none w-full"
            value={Email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="jhon@example.com"
          />
        </div>
        {/* Customer Ref */}
        <div className="w-full lg:w-1/4 flex flex-col">
          <label
            htmlFor="name"
            className="text-[#545454] text-[14px] font-semibold"
          >
            Customer Ref
          </label>
          <input
            type="text"
            name="customerRef"
            id=""
            className="text-[14px] py-[11px] px-[15px] rounded-lg outline-none w-full"
            value={CustomerRef}
            onChange={(e) => setCustomerRef(e.target.value)}
            placeholder=""
          />
        </div>
      </div>
      {/* button */}
      <div className="flex justify-center mb-5">
        <button
          className="text-white bg-[#0aa1dd] font-semibold text-[20px] py-2 px-4 rounded-lg"
          onClick={handleCreateInvoice}
        >
          {Loader === true ? (
            <Spinner animation="border" variant="light" />
          ) : (
            "CREATE QUICK INVOICE"
          )}
        </button>
      </div>
    </div>
  );
};

export default QuickInvoice;
