import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import swal from "sweetalert";
import { Logout } from "../../../store/actions/AuthActions";
import { useDispatch } from "react-redux";

const UpdateSocialMedia = () => {
  let { id, address, name } = useParams();
  let [searchParam] = useSearchParams();
  let sm = searchParam.get("sm");
  sm = atob(sm);
  const accessToken = JSON.parse(localStorage.getItem("userDetails"));
  let navigate = useNavigate();
  let Name = name;
  let Address = address;
  const [SocialMedia, setSocialMedia] = useState(sm);
  let urls = SocialMedia?.split(",");
  const [Insta, setInsta] = useState("");
  const [Facebook, setFacebook] = useState("");
  const [X, setX] = useState("");
  // Error handling
  const [Error, setError] = useState("");
  const [Loading, setLoading] = useState(false);

  // URL validation regex
  const facebookRegex =
    /^(https?:\/\/)?(www\.)?facebook\.com\/[a-zA-Z0-9._(\.\?)?]/;
  const instagramRegex =
    /^(https?:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9._(\.\?)?]/;
  // const xRegex =
  //   /^(https?:\/\/)?(www\.)?(twitter|x)\.com\/[a-zA-Z0-9._(\.\?)?]/;
  const xRegex =/^(https?:\/\/)?(www\.)?(twitter|x)\.com\/[a-zA-Z0-9_]+(?:\/[a-zA-Z0-9_]+)?$/


  const [FBUrl, setFBUrl] = useState(false);
  const [IGUrl, setIGUrl] = useState(false);
  const [XUrl, setXUrl] = useState(false);

  const combinedUrls = [Insta, Facebook, X].filter(Boolean).join(",");

  const dispatch = useDispatch();
  function onLogout() {
    dispatch(Logout(navigate));
    // window.location.reload();
  }

  const handleUpdate = () => {
    if (Name === "") {
      setError("name is required");
    } else if (Address === "") {
      setError("address is required");
    } else {
      setLoading(true);
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

      const raw = JSON.stringify({
        name: Name,
        address: Address,
        socialMediaAccount: combinedUrls,
      });

      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL + `client/update/${id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          if (result.message === "Successfully Updated") {
            setLoading(false);
            swal("Done!", "Social Media updated successfully", "success").then(
              () => navigate("/app-profile")
            );
          } else if (
            result.message === "unauthorized" ||
            result.message === "token expire"
          ) {
            swal({
              title: "Session Expired!",
              text: `your session has expired! Kindly login again!`,
              icon: "warning",
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                onLogout();
              } else {
                onLogout();
              }
            });
          } else {
            swal("Failed!", "Error updating the Social Media", "error");
            setLoading(false);
          }
        })
        .catch((error) => console.error(error));
    }
  };

  // Function to update state variables based on URL type
  const updateUrls = () => {
    urls.forEach((url) => {
      if (url.includes("instagram")) {
        setInsta(url);
      } else if (url.includes("facebook")) {
        setFacebook(url);
      } else {
        setX(url);
      }
    });
  };

  // Call updateUrls function when component mounts
  useEffect(() => {
    updateUrls();
  }, []);

  return (
    <div>
      <div className="flex justify-start md:justify-center animatedView">
        <div className="w-full md:w-1/2">
          <div className="bg-white p-3 rounded-[20px]">
            <div className="">
              <h1 className="text-[#fff] bg-[#0aa1dd] rounded-md p-3 m-2 text-xl">
                Update Social Media
              </h1>
            </div>
            <div className="flex items-center">
              <div className="mx-1.5 lg:mx-5 w-full flex flex-col gap-3 animatedView my-5">
                <div className="relative">
                  <label htmlFor="document" className="font-semibold">
                    Facebook Account:
                  </label>
                  <div className="flex flex-row-reverse gap-3 items-center">
                    <input
                      type="text"
                      className={`${
                        Error && Error.length > 0
                          ? "border border-red-500 placeholder:text-red-500"
                          : "border border-gray-200"
                      } border rounded-lg p-2 w-full`}
                      placeholder="Enter URL"
                      value={Facebook}
                      onChange={(e) => {
                        setFacebook(e.target.value);
                        const isValid = facebookRegex.test(e.target.value);
                        setFBUrl(!isValid);
                      }}
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 40 41"
                      fill="none"
                    >
                      <path
                        d="M40 20.4302C40 9.38455 31.0456 0.430176 20 0.430176C8.95437 0.430176 0 9.38455 0 20.4302C0 30.4127 7.31375 38.6869 16.875 40.1872V26.2114H11.7969V20.4302H16.875V16.0239C16.875 11.0114 19.8609 8.24267 24.4294 8.24267C26.6175 8.24267 28.9062 8.6333 28.9062 8.6333V13.5552H26.3844C23.8998 13.5552 23.125 15.0969 23.125 16.6786V20.4302H28.6719L27.7852 26.2114H23.125V40.1872C32.6862 38.6869 40 30.4128 40 20.4302Z"
                        fill="#1877F2"
                      />
                    </svg>
                  </div>
                  {FBUrl && (
                    <p className="text-red-500 font-semibold text-xs p-1 mt-1 animatedView">
                      {Facebook === "" ? "" : "please enter valid URL"}
                    </p>
                  )}
                </div>
                <div className="relative">
                  <label htmlFor="document" className="font-semibold">
                    Instagram Account:
                  </label>
                  <div className="flex flex-row-reverse gap-3 items-center">
                    <input
                      type="text"
                      className={`${
                        Error && Error.length > 0
                          ? "border border-red-500 placeholder:text-red-500"
                          : "border border-gray-200"
                      } border rounded-lg p-2 w-full`}
                      placeholder="Enter URL"
                      value={Insta}
                      onChange={(e) => {
                        setInsta(e.target.value);
                        const isValid = instagramRegex.test(e.target.value);
                        setIGUrl(!isValid);
                      }}
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 28.87 28.87"
                      width="30"
                      height="30"
                    >
                      <defs>
                        <linearGradient
                          id="a"
                          x1="-1.84"
                          x2="32.16"
                          y1="30.47"
                          y2="-3.03"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop
                            offset="0"
                            stopColor="#fed576"
                            className="stopColorfed576 svgShape"
                          ></stop>
                          <stop
                            offset=".26"
                            stopColor="#f47133"
                            className="stopColorf47133 svgShape"
                          ></stop>
                          <stop
                            offset=".61"
                            stopColor="#bc3081"
                            className="stopColorbc3081 svgShape"
                          ></stop>
                          <stop
                            offset="1"
                            stopColor="#4c63d2"
                            className="stopColor4c63d2 svgShape"
                          ></stop>
                        </linearGradient>
                      </defs>
                      <g
                        data-name="Layer 2"
                        fill="#000000"
                        className="color000 svgShape"
                      >
                        <g
                          data-name="Layer 1"
                          fill="#000000"
                          className="color000 svgShape"
                        >
                          <rect
                            width="28.87"
                            height="28.87"
                            fill="url(#a)"
                            rx="6.48"
                            ry="6.48"
                          ></rect>
                          <g
                            data-name="&lt;Group&gt;"
                            fill="#000000"
                            className="color000 svgShape"
                          >
                            <path
                              d="M20.83 0H8.04A8.12 8.12 0 000 8.04v12.79a8.12 8.12 0 008.04 8.04h12.79a8.12 8.12 0 008.04-8.04V8.04A8.12 8.12 0 0020.83 0zm6.57 20.83a6.58 6.58 0 01-6.57 6.57H8.04a6.58 6.58 0 01-6.57-6.57V8.04a6.58 6.58 0 016.57-6.57h12.79a6.58 6.58 0 016.57 6.57z"
                              fill="#ffffff"
                              className="colorffffff svgShape"
                            ></path>
                            <path
                              d="M14.43 7a7.43 7.43 0 107.43 7.43A7.43 7.43 0 0014.43 7zm0 12.48a5.05 5.05 0 115.05-5.05 5.05 5.05 0 01-5.05 5.05z"
                              fill="#ffffff"
                              className="colorffffff svgShape"
                            ></path>
                            <circle
                              cx="21.57"
                              cy="7.16"
                              r="1.74"
                              fill="#ffffff"
                              className="colorffffff svgShape"
                            ></circle>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                  {IGUrl && (
                    <p className="text-red-500 font-semibold text-xs p-1 mt-1 animatedView">
                      {Insta === "" ? "" : "please enter valid URL"}
                    </p>
                  )}
                </div>
                <div className="relative">
                  <label htmlFor="document" className="font-semibold">
                    X Account:
                  </label>
                  <div className="flex flex-row-reverse gap-3 items-center">
                    <input
                      type="text"
                      className={`${
                        Error && Error.length > 0
                          ? "border border-red-500 placeholder:text-red-500"
                          : "border border-gray-200"
                      } border rounded-lg p-2 w-full`}
                      placeholder="Enter URL"
                      value={X}
                      onChange={(e) => {
                        setX(e.target.value);
                        const isValid = xRegex.test(e.target.value);
                        setXUrl(!isValid);
                      }}
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 2500 2500"
                      width="30"
                      height="30"
                    >
                      <path
                        d="M0 1250C0 559.64 559.64 0 1250 0s1250 559.64 1250 1250-559.64 1250-1250 1250S0 1940.36 0 1250z"
                        fill="#000"
                      />
                      <path
                        d="M1335.48 1254.04L1920 530h-385.08l-339.82 422.66L857.98 530H500l531.52 659.92L500 1848h382.22l341.88-436.74L1588.98 1848H1960l-624.52-789.96z"
                        fill="#fff"
                      />
                    </svg>
                  </div>
                  {XUrl && (
                    <p className="text-red-500 font-semibold text-xs p-1 mt-1 animatedView">
                      {X === "" ? "" : "please enter valid URL"}
                    </p>
                  )}
                </div>
                <div className="flex flex-col gap-3 justify-center items-center">
                  <div className="flex items-center gap-3">
                    <button
                      className="border border-[#0aa1dd] text-[#0aa1dd] rounded p-2.5 px-4"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                    {Loading ? (
                      <div className="p-2">
                        <Spinner animation="border" size="lg" />
                      </div>
                    ) : (
                      <button
                        type="submit"
                        className="flex w-1/2 justify-center items-center gap-2 bg-[#0aa1dd] hover:bg-[#0992c7] transition-all duration-200 text-[#fff] font-semibold rounded-md px-3 py-1 m-2 ml-0 cursor-pointer disabled:cursor-not-allowed disabled:bg-[#64a9c5]"
                        onClick={handleUpdate}
                        disabled={
                          (XUrl && X !== "") ||
                          (IGUrl && Insta !== "") ||
                          (FBUrl && Facebook !== "")
                        }
                      >
                        <span className="p-1 text-xl">Update</span>
                      </button>
                    )}
                  </div>
                  {Error && Error.length > 0 && (
                    <p className="text-red-500 font-semibold text-xs p-1 mt-1 animatedView">
                      {Error}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UpdateSocialMedia;
