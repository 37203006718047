import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
// import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import Number from "../../../jsxClient/components/NumberCounter/Number";
import homeBG from "../../../images/homeBG.png";
import { Link } from "react-router-dom";
import { Empty } from "antd";
import { connect, useDispatch } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Logout } from "../../../store/actions/AuthActions";
import { isAuthenticated } from "../../../store/selectors/AuthSelectors";
import swal from "sweetalert";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

const Home = () => {
  let accessToken = JSON.parse(localStorage.getItem("userDetails"));

  //Time Bar
  const [StartDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [EndDate, setEndDate] = useState(
    moment().add(1, "days").format("YYYY-MM-DD")
  );
  const [dateRangeText, setDateRangeText] = useState(
    `${moment().format("DD/MM/YYYY")} - ${moment()
      .add(1, "days")
      .format("DD/MM/YYYY")}`
  );
  const [time, setTime] = useState("Today");
  const sortByTime = [
    { name: "Today" },
    { name: "Weekly" },
    { name: "Monthly" },
  ];

  const initialStartDate = moment().startOf("day");
  const initialEndDate = moment().endOf("day");

  const handleDateRangeChange = (event, picker) => {
    if (picker.startDate.format("YYYY-MM-DD") > moment().format("YYYY-MM-DD")) {
      toast.error("You Cannot see the future!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setStartDate(picker.startDate.format("YYYY-MM-DD"));
      setEndDate(picker.endDate.format("YYYY-MM-DD"));
      //setIsTodayDisabled(picker.startDate.format("YYYY-MM-DD")<=moment().format("YYYY-MM-DD")  && picker.endDate.format("YYYY-MM-DD")>moment().format("YYYY-MM-DD"));
      setDateRangeText(
        `${picker.startDate.format("DD/MM/YYYY")} - ${picker.endDate.format(
          "DD/MM/YYYY"
        )}`
      );
    }
  };

  const handleChangeDate = (el) => {
    setTime(el.name);
    // if (el.name === "Today") {
    //   setStartDate(moment().format("YYYY-MM-DD"));
    //   setEndDate(moment().add(1, "days").format("YYYY-MM-DD"));
    // } else if (el.name === "Weekly") {
    //   setStartDate(moment().subtract(7, "days").format("YYYY-MM-DD"));
    //   setEndDate(moment().format("YYYY-MM-DD"));
    // } else if (el.name === "Monthly") {
    //   setStartDate(moment().subtract(30, "days").format("YYYY-MM-DD"));
    //   setEndDate(moment().format("YYYY-MM-DD"));
    // }
    const ranges = {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
      "Last 7 Days": [moment().subtract(6, "days"), moment()],
      "Last 30 Days": [moment().subtract(29, "days"), moment()],
      "This Month": [moment().startOf("month"), moment().endOf("month")],
      "Last Month": [
        moment().subtract(1, "month").startOf("month"),
        moment().subtract(1, "month").endOf("month"),
      ],
    };
    const [start, end] = ranges[el.name];
    setStartDate(start);
    setEndDate(end);
    setDateRangeText(
      `${start.format("MMMM D, YYYY")} - ${end.format("MMMM D, YYYY")}`
    );
  };
  const predefinedRanges = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "Last 7 Days": [moment().subtract(6, "days"), moment()],
    "Last 30 Days": [moment().subtract(29, "days"), moment()],
    "This Month": [moment().startOf("month"), moment().endOf("month")],
    "Last Month": [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
  };

  const [showA, setShowA] = useState(true);
  const toggleShowA = () => setShowA(!showA);

  const [Data, setData] = useState();

  let navigate = useNavigate();

  const dispatch = useDispatch();
  function onLogout() {
    dispatch(Logout(navigate));
    // window.location.reload();
  }

  useEffect(() => {
    const getDashboardData = async () => {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      await fetch(
        process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL +
          `dashboard?startDate=${StartDate}&endDate=${EndDate}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          // console.log(result);
          if (result.code === "ACC_DISABLED") {
            onLogout();
            // window.location.reload();
          } else if (result.message === "unauthorized" || result.message === "token expire") {
            swal({
              title: "Session Expired!",
              text: `your session has expired! Kindly login again!`,
              icon: "warning",
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                onLogout();
              } else {
                onLogout();
              }
            });
          } else {
            setData(result);
          }
        })
        .catch((error) => console.log("error", error));
    };

    getDashboardData();
  }, [accessToken.accessToken, StartDate, EndDate]);

  return (
    <div
      style={{
        transform: "scale(.87)",
        transformOrigin: "0 0",
        width: "90vw",
        height: "",
      }}
    >
      <div className="row animatedView ">
        <div className="col-xl-12">
          <div className="row">
            {/* calendar */}
            <div className="flex gap-[17px] justify-end">
              <div className="p-2 rounded-[6px] bg-white mb-3">
                <DateRangePicker
                  initialSettings={{
                    startDate: initialStartDate.format("MM/DD/YYYY"),
                    endDate: initialEndDate.format("MM/DD/YYYY"),
                    ranges: predefinedRanges,
                  }}
                  onApply={handleDateRangeChange}
                >
                  <button className="text-[#0aa1dd] font-[500] text-base px-2 p-1 flex items-center gap-2">
                    {dateRangeText}
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        fontWeight={"200"}
                      >
                        <path
                          d="M13.6 1.6H12V0.8C12 0.587827 11.9157 0.384344 11.7657 0.234315C11.6157 0.0842854 11.4122 0 11.2 0C10.9878 0 10.7843 0.0842854 10.6343 0.234315C10.4843 0.384344 10.4 0.587827 10.4 0.8V1.6H5.6V0.8C5.6 0.587827 5.51571 0.384344 5.36569 0.234315C5.21566 0.0842854 5.01217 0 4.8 0C4.58783 0 4.38434 0.0842854 4.23431 0.234315C4.08429 0.384344 4 0.587827 4 0.8V1.6H2.4C1.76348 1.6 1.15303 1.85286 0.702944 2.30294C0.252856 2.75303 0 3.36348 0 4V13.6C0 14.2365 0.252856 14.847 0.702944 15.2971C1.15303 15.7471 1.76348 16 2.4 16H13.6C14.2365 16 14.847 15.7471 15.2971 15.2971C15.7471 14.847 16 14.2365 16 13.6V4C16 3.36348 15.7471 2.75303 15.2971 2.30294C14.847 1.85286 14.2365 1.6 13.6 1.6ZM14.4 13.6C14.4 13.8122 14.3157 14.0157 14.1657 14.1657C14.0157 14.3157 13.8122 14.4 13.6 14.4H2.4C2.18783 14.4 1.98434 14.3157 1.83431 14.1657C1.68429 14.0157 1.6 13.8122 1.6 13.6V8H14.4V13.6ZM14.4 6.4H1.6V4C1.6 3.78783 1.68429 3.58434 1.83431 3.43431C1.98434 3.28429 2.18783 3.2 2.4 3.2H4V4C4 4.21217 4.08429 4.41566 4.23431 4.56569C4.38434 4.71571 4.58783 4.8 4.8 4.8C5.01217 4.8 5.21566 4.71571 5.36569 4.56569C5.51571 4.41566 5.6 4.21217 5.6 4V3.2H10.4V4C10.4 4.21217 10.4843 4.41566 10.6343 4.56569C10.7843 4.71571 10.9878 4.8 11.2 4.8C11.4122 4.8 11.6157 4.71571 11.7657 4.56569C11.9157 4.41566 12 4.21217 12 4V3.2H13.6C13.8122 3.2 14.0157 3.28429 14.1657 3.43431C14.3157 3.58434 14.4 3.78783 14.4 4V6.4Z"
                          fill="#0AA1DD"
                        />
                      </svg>
                    </span>
                  </button>
                </DateRangePicker>
              </div>
            </div>
            {/* Main Crds */}
            <div className="col-xl-6">
              <div className="row">
                <div className="col-xl-12">
                  <div
                    className="card h-auto shadow-2xl"
                    style={{
                      backgroundImage: `url(${homeBG})`,
                      backgroundRepeat: "no-repeat",
                      width: "100%",
                      backgroundSize: "cover",
                    }}
                  >
                    <div
                      className="card-body row py-20"
                      style={{ margin: "32px 0px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 25,
                          marginTop: 35,
                        }}
                      >
                        <div>
                          <h4 style={{ color: "#fff" }}>
                            Number of Paid Invoices
                          </h4>
                          <Number
                            style={{
                              color: "#fff",
                              fontWeight: 700,
                              fontSize: "55px",
                            }}
                            endValue={Data ? Data.invoicesPaid : 0}
                          />
                        </div>
                        <div>
                          <h4 style={{ color: "#fff" }}>
                            Value of Paid Invoices
                          </h4>
                          <p
                            style={{
                              color: "#fff",
                              fontWeight: 700,
                              fontSize: "55px",
                            }}
                          >
                            {Data ? parseFloat(Data.valueOfPaidInvoices) : 0}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Grid Cards */}
            <div className="col-xl-6">
              <div className="row">
                <div className="col-xl-12">
                  <div className="row">
                    <div className="col-xl-6 col-sm-6">
                      <div
                        className="card"
                        style={{
                          boxShadow: "13px 13px 13px 0px rgba(0,0,0,0.1)",
                        }}
                      >
                        <div className="card-body d-flex p-5 justify-content-between">
                          <div>
                            <h4 className="fs-18 font-w500 text-[#545454] mb-4">
                              Invoice Created
                            </h4>
                            <div className="d-flex align-items-center py-1">
                              <Number
                                className="font-w700 mb-0 text-[55px]"
                                endValue={Data ? Data?.invoicesCreated : 0}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-6 col-sm-6">
                      <div
                        className="card cardShadow"
                        style={{
                          boxShadow: "13px 13px 13px 0px rgba(0,0,0,0.1)",
                        }}
                      >
                        <div className="card-body d-flex p-5 justify-content-between">
                          <div>
                            <h4 className="fs-18 font-w500 text-[#545454] mb-4">
                              Number of Customers
                            </h4>
                            <div className="d-flex align-items-center">
                              <Number
                                className="font-w700 mb-0 text-[55px]"
                                endValue={Data ? Data?.customers : 0}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-6 col-sm-6 pt-1.5">
                      <div
                        className="card"
                        style={{
                          boxShadow: "13px 13px 13px 0px rgba(0,0,0,0.1)",
                        }}
                      >
                        <div className="card-body d-flex p-5 justify-content-between">
                          <div>
                            <h4 className="fs-18 font-w500 text-[#545454] mb-4">
                              Value of Transactions
                            </h4>
                            <div className="d-flex align-items-center">
                              {/* <Number
                                className=" font-w700 mb-0 text-[55px]"
                                endValue={Data ? Data?.valuesOfTransactions : 0}
                              /> */}
                              <p className="font-w700 mb-0 text-[55px]">
                                {Data
                                  ? parseFloat(Data?.valuesOfTransactions)
                                  : 0}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-6 col-sm-6 pt-1.5">
                      <div
                        className="card"
                        style={{
                          boxShadow: "13px 13px 13px 0px rgba(0,0,0,0.1)",
                        }}
                      >
                        <div className="card-body d-flex p-5 justify-content-between">
                          <div>
                            <h4 className="fs-18 font-w500 text-[#545454] mb-4">
                              Expiring Invoices
                            </h4>
                            <div className="d-flex align-items-center">
                              <Number
                                className=" font-w700 mb-0 text-[55px]"
                                endValue={
                                  Data ? Data?.expiringInvoice?.length : 0
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-3 -mt-1">
              {/* Buttons */}
              <div className="flex gap-3 items-center w-full md:w-full lg:w-1/2">
                <Link
                  to="/create-new-invoice"
                  className="bg-[#0AA1DD] p-5 md:p-5 text-[10px] md:text-[18px] w-auto font-semibold text-white rounded-lg flex gap-2 items-center transition-all ease-in-out duration-500 hover:scale-105"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 26 26"
                    fill="none"
                  >
                    <path
                      d="M0 3.25C0 2.38805 0.34241 1.5614 0.951903 0.951903C1.5614 0.34241 2.38805 0 3.25 0H16.8409C17.2677 0 17.6903 0.0840637 18.0846 0.247392C18.4789 0.410719 18.8372 0.650113 19.139 0.951903C19.4408 1.25369 19.6802 1.61197 19.8435 2.00628C20.0068 2.40059 20.0909 2.8232 20.0909 3.25V16.5455H26V21.5682C26 22.7436 25.5331 23.8708 24.702 24.702C23.8708 25.5331 22.7436 26 21.5682 26H4.43182C3.25643 26 2.12918 25.5331 1.29805 24.702C0.466922 23.8708 0 22.7436 0 21.5682V3.25ZM20.0909 24.2273H21.5682C22.2734 24.2273 22.9498 23.9471 23.4484 23.4484C23.9471 22.9498 24.2273 22.2734 24.2273 21.5682V18.3182H20.0909V24.2273ZM4.72727 6.79545C4.72727 7.28473 5.12436 7.68182 5.61364 7.68182H14.4773C14.7124 7.68182 14.9378 7.58843 15.104 7.42221C15.2703 7.25598 15.3636 7.03053 15.3636 6.79545C15.3636 6.56038 15.2703 6.33493 15.104 6.1687C14.9378 6.00248 14.7124 5.90909 14.4773 5.90909H5.61364C5.37856 5.90909 5.15311 6.00248 4.98688 6.1687C4.82066 6.33493 4.72727 6.56038 4.72727 6.79545ZM5.61364 11.8182C5.37856 11.8182 5.15311 11.9116 4.98688 12.0778C4.82066 12.244 4.72727 12.4695 4.72727 12.7045C4.72727 12.9396 4.82066 13.1651 4.98688 13.3313C5.15311 13.4975 5.37856 13.5909 5.61364 13.5909H14.4773C14.7124 13.5909 14.9378 13.4975 15.104 13.3313C15.2703 13.1651 15.3636 12.9396 15.3636 12.7045C15.3636 12.4695 15.2703 12.244 15.104 12.0778C14.9378 11.9116 14.7124 11.8182 14.4773 11.8182H5.61364ZM4.72727 18.6136C4.72727 19.1029 5.12436 19.5 5.61364 19.5H9.75C9.98508 19.5 10.2105 19.4066 10.3768 19.2404C10.543 19.0742 10.6364 18.8487 10.6364 18.6136C10.6364 18.3786 10.543 18.1531 10.3768 17.9869C10.2105 17.8207 9.98508 17.7273 9.75 17.7273H5.61364C5.37856 17.7273 5.15311 17.8207 4.98688 17.9869C4.82066 18.1531 4.72727 18.3786 4.72727 18.6136Z"
                      fill="white"
                    />
                  </svg>
                  Create Invoice
                </Link>
                <Link
                  to="/create-customer"
                  className="bg-[#0AA1DD] p-5 md:p-5 lg:p-5 text-[10px] md:text-[18px] font-semibold text-white rounded-lg flex gap-3 items-center transition-all ease-in-out duration-500 hover:scale-105 mr-3"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="17"
                    viewBox="0 0 28 24"
                    fill="none"
                  >
                    <path
                      d="M22.3999 20.8V24H0V20.8C0 20.8 0 14.4 11.2 14.4C22.3999 14.4 22.3999 20.8 22.3999 20.8ZM16.7999 5.60006C16.7999 4.49249 16.4715 3.40979 15.8562 2.48888C15.2408 1.56797 14.3662 0.850207 13.343 0.426357C12.3197 0.00250824 11.1937 -0.10839 10.1075 0.107686C9.02117 0.323763 8.02335 0.857109 7.24018 1.64028C6.45701 2.42345 5.92366 3.42127 5.70758 4.50756C5.49151 5.59385 5.60241 6.71982 6.02625 7.74308C6.4501 8.76635 7.16787 9.64094 8.08878 10.2563C9.00969 10.8716 10.0924 11.2 11.2 11.2C12.6852 11.2 14.1095 10.61 15.1597 9.55985C16.2099 8.50965 16.7999 7.08527 16.7999 5.60006ZM22.3039 14.4C23.2875 15.1612 24.0924 16.1287 24.6618 17.2344C25.2312 18.3402 25.5515 19.5572 25.5999 20.8V24H31.9999V20.8C31.9999 20.8 31.9999 14.992 22.3039 14.4ZM20.7999 8.41261e-05C19.6986 -0.0060482 18.6216 0.323212 17.7119 0.944081C18.6838 2.30205 19.2064 3.93013 19.2064 5.60006C19.2064 7.27 18.6838 8.89807 17.7119 10.256C18.6216 10.8769 19.6986 11.2062 20.7999 11.2C22.2851 11.2 23.7095 10.61 24.7597 9.55985C25.8099 8.50965 26.3999 7.08527 26.3999 5.60006C26.3999 4.11486 25.8099 2.69048 24.7597 1.64028C23.7095 0.590081 22.2851 8.41261e-05 20.7999 8.41261e-05Z"
                      fill="white"
                    />
                  </svg>
                  Add Customer
                </Link>
              </div>
              {/* Table */}
              <div className="flex flex-col md:flex-col lg:flex-row gap-[18px] md:gap-[28px] mb-5 mt-2.5">
                <div className="bg-[#fff] p-5 rounded-xl shadow-2xl w-full md:w-full lg:w-1/2">
                  <h1 className="font-semibold text-[#0aa1dd] py-2 text-lg">
                    Today’s Invoice
                  </h1>
                  <div className="relative overflow-x-scroll">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 overflow-x-scroll">
                      <thead className="text-xs text-gray-700 uppercase bg-[#eee]">
                        <tr>
                          <th scope="col" className="px-4 py-3">
                            Payment Ref
                          </th>
                          <th scope="col" className="px-4 py-3">
                            Invoice Value
                          </th>
                          <th scope="col" className="px-4 py-3 w-40">
                            Invoice Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Data &&
                          Data.todaysInvoice?.length > 0 &&
                          Data.todaysInvoice?.slice(0, 5)?.map((el, i) => {
                            return (
                              <tr className="bg-white border-b" key={i}>
                                <td className="px-3 py-2 text-[#545454] text-nowrap">
                                  {el.paymentRef}
                                </td>
                                <td className="px-3 py-2 text-[#545454] text-nowrap">
                                  {el.amount}
                                </td>
                                <td className="px-3 py-2 text-[#545454] cursor-pointer text-center">
                                  {el.status === "pending" ? (
                                    <span className="text-orange-500 font-semibold capitalize">
                                      {el.status}
                                    </span>
                                  ) : (
                                    <span className="text-green-500 font-semibold capitalize">
                                      {el.status}
                                    </span>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                    {Data?.todaysInvoice?.length > 0 && (
                      <Link
                        to="/invoice-list"
                        className="flex justify-end underline my-2"
                      >
                        see all
                      </Link>
                    )}
                    {Data?.todaysInvoice?.length === 0 && (
                      <div className="w-full flex justify-center my-5">
                        <div>
                          <Empty />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="bg-[#fff] p-5 rounded-xl shadow-2xl w-full md:w-full lg:w-1/2">
                  <h1 className="font-semibold text-[#0aa1dd] text-primary text-lg py-2">
                    Today’s Paid Invoice
                  </h1>
                  <div className="relative overflow-x-scroll">
                    <table className="w-full text-sm text-left rtl:text-right overflow-x-scroll">
                      <thead className="text-xs text-gray-700 uppercase bg-[#eee]">
                        <tr>
                          <th scope="col" className="px-3 py-[12.5px]">
                            Payment Ref
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-[12.5px] whitespace-nowrap"
                          >
                            Invoice Value
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-[12.5px] whitespace-nowrap"
                          >
                            Commission
                          </th>
                          <th scope="col" className="px-3 py-[12.5px]">
                            Transaction ID
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Data?.todaysPaidInvoices?.length > 0 &&
                          Data?.todaysPaidInvoices
                            ?.slice(0, 5)
                            ?.map((el, i) => {
                              return (
                                <tr className="bg-white border-b" key={i}>
                                  <th
                                    scope="row"
                                    className="px-3 py-[12.5px] font-semibold text-gray-900 whitespace-nowrap"
                                  >
                                    {el.paymentRef}
                                  </th>
                                  <td className="px-3 py-[12.5px]">
                                    {el.amount + " " + el.currency}
                                  </td>
                                  <td className="px-3 py-[12.5px]">-</td>
                                  <td className="px-3 py-[12.5px]">
                                    {el.transactionId}
                                  </td>
                                </tr>
                              );
                            })}
                      </tbody>
                    </table>
                    {Data?.todaysPaidInvoices?.length > 0 && (
                      <Link
                        to="/invoice-list"
                        className="flex justify-end underline my-2"
                      >
                        see all
                      </Link>
                    )}
                    {Data?.todaysPaidInvoices?.length === 0 && (
                      <div className="w-full flex justify-center my-5">
                        <div>
                          <Empty />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
// export default /;

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(Home));
