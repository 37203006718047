import { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Input } from "../Components";
import headerImage from "../Images/rect-header.png";
import "./index.css";
import { useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";

const ForgotPassword = () => {
  const EmailInputRef = useRef(null);
  const passwordInputRef = useRef(null);

  const [Password, setPassword] = useState("");
  const [ConfPass, setConfPass] = useState("");

  // Password Visibility Hook
  const [passType, setPassType] = useState("password");

  const hidePassword = (e) => {
    e.preventDefault();
    setPassType("password");
  };
  const showPassword = (e) => {
    e.preventDefault();
    setPassType("text");
  };

  // Error Hooks
  const [EmailError, setEmailError] = useState("");
  const [PasswordError, setPasswordError] = useState("");

  // Loading Hooks
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setEmailError("");
    setPasswordError("");

    setLoading(true);
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      password: ConfPass,
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImI2MTNiODhmLTJhMGUtNGZlMi04ZWU3LWEwZTM2NGRiODBiYyIsImlhdCI6MTcxNzY2OTc1MiwiZXhwIjoxNzE3NjcwMDUyfQ.NZerltvBqUjJJ522AEUtM40Pedc59owml8uiztJhc4I",
      id: "8bce174c-ba0a-4daf-be1a-8849f058eca8",
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL + "auth/reset-password",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => console.log(result))
      .catch((error) => console.error(error));
  };

  return (
    <div className="background-1-login" style={{ height: "100vh" }}>
      <header
        className="wrapper-login header-login"
        style={{ paddingBottom: "100px" }}
      >
        <span className="text-lg text-bold text-light-login">LOG IN</span>
        <img src={headerImage} alt="" />
      </header>
      <form className="wrapper-login card-login animatedView">
        <div className="form-group-login">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <label htmlFor="name" className="text-sm text-bold text-dark-login">
              Password
            </label>
          </div>
          <Input
            ref={passwordInputRef}
            type={passType}
            placeholder="*******"
            id="name"
            value={Password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="form-group-login">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <label
              htmlFor="password"
              className="text-sm text-bold text-dark-login"
            >
              Confirm Password
            </label>
            {PasswordError && (
              <div
                className="animatedView"
                style={{ color: "red", fontSize: 10, transition: "all" }}
              >
                {PasswordError}
              </div>
            )}
          </div>
          <div style={{ position: "relative" }}>
            <Input
              ref={passwordInputRef}
              type={passType}
              placeholder="*******"
              id="password"
              value={ConfPass}
              onChange={(e) => setConfPass(e.target.value)}
            />
            <div style={{ position: "absolute", top: 15, right: 15 }}>
              {passType === "password" ? (
                <div className="cursor-pointer" onClick={showPassword}>
                  <i
                    className="bi bi-eye-slash-fill h2"
                    style={{ fontSize: "2rem", color: "#A1A1A1" }}
                  ></i>
                </div>
              ) : (
                <div className="cursor-pointer" onClick={hidePassword}>
                  <i
                    className="bi bi-eye-fill h2"
                    style={{ fontSize: "2rem", color: "#A1A1A1" }}
                  ></i>
                </div>
              )}
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "40px",
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? (
              <Spinner animation="border" variant="light" />
            ) : (
              "Submit"
            )}
          </Button>
        </div>
        <div style={{ textAlign: "right", paddingTop: "40px" }}>
          <Link to="/login" className="link-login text-sm text-dark-login">
            Already have account.{" "}
            <span style={{ fontWeight: "bold", color: "#0aa1dd" }}>Login?</span>
          </Link>
        </div>
      </form>   

      <p
        className="wrapper-login text-sm"
        style={{
          margin: "auto",
          textAlign: "center",
          paddingTop: "40px",
          paddingBottom: "40px",
        }}
      >
        Don't you have an account?{" "}
        <Link to="/page-register" className="text-primary text-bold">
          REGISTER
        </Link>
      </p>
    </div>
  );
};

export default ForgotPassword;
