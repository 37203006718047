import React from "react";
import info from "../../../../../images/svg/info.svg";

const OtherInfo = ({ Card, Label, Desc, data }) => {
  return (
    <Card Head="Other Info" Icons={info}>
      <div>
        <Label label="Invoice Option" />
        <Desc desc="-" />
      </div>
      <div>
        <Label label="Condition apply" />
        <Desc desc={data?.termsAndConditions === "" ? "No" : "Yes"} />
      </div>
      <div>
        <Label label="" />
        <Desc desc="" />
      </div>
      <div>
        <Label label="Notes" />
        <Desc desc={data?.comments} />
      </div>
    </Card>
  );
};

export default OtherInfo;
