import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import swal from "sweetalert";
import { Logout } from "../../../store/actions/AuthActions";

const UpdateCompany = () => {
  let { id, address, name } = useParams();
  let [searchParam] = useSearchParams();
  let sm = searchParam.get("sm");
  const accessToken = JSON.parse(localStorage.getItem("userDetails"));
  let Navigate = useNavigate();
  // console.log(accessToken);

  const [Name, setName] = useState(name);
  const [Address, setAddress] = useState(address);

  //   Error handling
  const [Error, setError] = useState();
  const [Loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  function onLogout() {
    dispatch(Logout(Navigate));
    // window.location.reload();
  }

  const handleUpdate = () => {
    if (Name === "") {
      setError("name is required");
    } else if (Address === "") {
      setError("address is required");
    } else {
      setLoading(true);
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

      const raw = JSON.stringify({
        name: Name,
        address: Address,
        socialMediaAccount: sm,
      });

      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL + `client/update/${id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          if (result.message === "Successfully Updated") {
            setLoading(false);
            swal(
              "Done!",
              "Company details updated successfully",
              "success"
            ).then(() => Navigate("/app-profile"));
          } else if (result.message === "unauthorized" || result.message === "token expire") {
            swal({
              title: "Session Expired!",
              text: `your session has expired! Kindly login again!`,
              icon: "warning",
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                onLogout();
              } else {
                onLogout();
              }
            });
          } else {
            swal("Failed!", "Error updating the Company Details", "error");
            setLoading(false);
          }
        })
        .catch((error) => console.error(error));
    }
  };
  return (
    <div>
      <div className="flex justify-start md:justify-center animatedView">
        <div className="w-full md:w-1/2">
          <div className="bg-white p-3 rounded-[20px]">
            <div className="">
              <h1 className="text-[#fff] bg-[#0aa1dd] rounded-md p-3 m-2 text-xl">
                Update Company Details
              </h1>
            </div>
            <div className="flex items-center">
              <div className="mx-1.5 md:mx-5 w-full flex flex-col gap-3 animatedView my-5">
                <div className="relative">
                  <label htmlFor="document" className="font-semibold">
                    Name:
                  </label>
                  <input
                    type="text"
                    className={` border rounded-lg p-2 w-full`}
                    placeholder="enter company's name"
                    value={Name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="relative mt-2">
                  <label htmlFor="document" className="font-semibold">
                    Address:
                  </label>
                  <input
                    type="text"
                    className={`border rounded-lg p-2 w-full`}
                    placeholder="enter company's address"
                    value={Address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
                {Error && (
                  <div className="text-xs m-1 text-red-500 animatedView">
                    {Error}
                  </div>
                )}
                {/* Button */}
                <div className="flex justify-end mt-2">
                  <div className="flex justify-between gap-4">
                    <button
                      className="border border-[#0aa1dd] text-[#0aa1dd] rounded p-2 px-4"
                      onClick={() => Navigate(-1)}
                    >
                      Cancel
                    </button>
                    <button
                      className="p-2.5 bg-[#0aa1dd] rounded-md text-white px-3 flex items-center gap-3"
                      onClick={handleUpdate}
                    >
                      {Loading ? (
                        <Spinner animation="border" variant="light"></Spinner>
                      ) : (
                        <>
                          Update Company Details
                          <span>
                            <i className="bi bi-check2-all"></i>
                          </span>
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateCompany;
