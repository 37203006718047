import React, { useState, useEffect } from "react";
import { useSpring, animated } from "react-spring";

const Number = ({ endValue, className, style }) => {
  const [startAnimation, setStartAnimation] = useState(false);

  useEffect(() => {
    setStartAnimation(true);
  }, []);

  const { number } = useSpring({
    from: { number: 0 },
    number: startAnimation ? endValue : 0,
    delay: 100, // Adjust delay as needed
    config: { duration: 1500 }, // Adjust duration as needed
  });

  return (
    <animated.span className={className} style={style}>
      {number.to((val) => Math.floor(val))}
    </animated.span>
  );
};

export default Number;
