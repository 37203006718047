import React from "react";
import customer from "../../../../../images/svg/Customer.svg";

const CustomerInfo = ({ Card, Label, Desc, data, customerRef }) => {
  // console.log(data);
  return (
    <Card Head="Customer Info" Icons={customer}>
      <div>
        <Label label="Customer Name" />
        <Desc desc={data?.name} />
      </div>
      <div>
        <Label label="Customer Ref" />
        <Desc desc={customerRef} />
      </div>
      <div>
        <Label label="Customer Mobile" />
        <Desc desc={data?.mobileNumber} />
      </div>
      <div>
        <Label label="Customer Email" />
        <Desc desc={data?.email} />
      </div>
    </Card>
  );
};

export default CustomerInfo;
