import React, { useContext } from "react";

import { Link } from "react-router-dom";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

// import { FaFileInvoice } from "react-icons/fa";
import { Dropdown } from "react-bootstrap";
import { LocalStorageContext } from "../../../context/WalletProvider";
import { HiArrowTrendingUp } from "react-icons/hi2";
import { useNotifications } from "../../../context/Notification";
// import LogoutPage from "./Logout";

const Header = ({ onNote }) => {
  let accessToken = JSON.parse(localStorage.getItem("userDetails"));
  // const [searchBut, setSearchBut] = useState(false);
  var path = window.location.pathname.split("/");
  var name = path[path.length - 1].split("-");
  var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
  // console.log(filterName);
  var finalName = filterName.includes("app")
    ? filterName.filter((f) => f !== "app")
    : filterName.includes("ui")
    ? filterName.filter((f) => f !== "ui")
    : filterName.includes("uc")
    ? filterName.filter((f) => f !== "uc")
    : filterName.includes("basic")
    ? filterName.filter((f) => f !== "basic")
    : filterName.includes("jquery")
    ? filterName.filter((f) => f !== "jquery")
    : filterName.includes("table")
    ? filterName.filter((f) => f !== "table")
    : filterName.includes("page")
    ? filterName.filter((f) => f !== "page")
    : filterName.includes("email")
    ? filterName.filter((f) => f !== "email")
    : filterName.includes("ecom")
    ? filterName.filter((f) => f !== "ecom")
    : filterName.includes("chart")
    ? filterName.filter((f) => f !== "chart")
    : filterName.includes("editor")
    ? filterName.filter((f) => f !== "editor")
    : filterName[0] === "app" && filterName[1] === "profile"
    ? "Profile"
    : filterName[0] === "new" && filterName[1] === "invoice"
    ? "Create Invoice"
    : filterName[0] === "invoice" && filterName[1] === "list"
    ? "Invoice List"
    : filterName[0] === "change" && filterName[1] === "password"
    ? "Password"
    : filterName[0] === "balance" && filterName[1] === "history"
    ? "Balance History"
    : filterName[0] === "update" && filterName[1] === "user"
    ? "Update Profile"
    : filterName[0] === "update" && filterName[1] === "invoice"
    ? "Update Invoice"
    : filterName[0] === "invoice" && filterName[1] === "detail"
    ? "Invoice Details"
    : decodeURIComponent(filterName);

  let username = JSON.parse(localStorage.getItem("userDetails"));
  const { refresh, Notification, IsNewNotification, setIsNewNotification } =
    useNotifications();
  const handleNotificationClick = () => {
    setIsNewNotification(false);
    // refresh(); // Optionally refresh the notifications if necessary
  };
  const { data } = useContext(LocalStorageContext);

  return (
    <div className="header border-bottom bg-white">
      {/* {console.log(filterName[0] === "app" && filterName[1] === "profile")} */}
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div
                className="dashboard_bar"
                style={{ textTransform: "capitalize" }}
              >
                {decodeURIComponent(finalName)}
              </div>
            </div>
            <ul className="navbar-nav header-right main-notification">
              <Link
                to="/create-new-invoice"
                className="text-[#0aa1dd]"
                style={{ marginTop: 20 }}
              >
                <svg
                  width="31"
                  height="31"
                  viewBox="0 0 31 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 3.875C0 2.84729 0.408258 1.86166 1.13496 1.13496C1.86166 0.408258 2.84729 0 3.875 0H20.0795C20.5884 0 21.0923 0.10023 21.5624 0.294967C22.0326 0.489704 22.4598 0.775134 22.8196 1.13496C23.1794 1.49479 23.4648 1.92196 23.6596 2.3921C23.8543 2.86224 23.9545 3.36613 23.9545 3.875V19.7273H31V25.7159C31 27.1173 30.4433 28.4614 29.4523 29.4523C28.4614 30.4433 27.1173 31 25.7159 31H5.28409C3.88266 31 2.53863 30.4433 1.54767 29.4523C0.556715 28.4614 0 27.1173 0 25.7159V3.875ZM23.9545 28.8864H25.7159C26.5568 28.8864 27.3632 28.5523 27.9578 27.9578C28.5523 27.3632 28.8864 26.5568 28.8864 25.7159V21.8409H23.9545V28.8864ZM5.63636 8.10227C5.63636 8.68564 6.10982 9.15909 6.69318 9.15909H17.2614C17.5416 9.15909 17.8105 9.04775 18.0086 8.84956C18.2068 8.65136 18.3182 8.38256 18.3182 8.10227C18.3182 7.82199 18.2068 7.55318 18.0086 7.35499C17.8105 7.1568 17.5416 7.04545 17.2614 7.04545H6.69318C6.4129 7.04545 6.14409 7.1568 5.9459 7.35499C5.74771 7.55318 5.63636 7.82199 5.63636 8.10227ZM6.69318 14.0909C6.4129 14.0909 6.14409 14.2023 5.9459 14.4004C5.74771 14.5986 5.63636 14.8674 5.63636 15.1477C5.63636 15.428 5.74771 15.6968 5.9459 15.895C6.14409 16.0932 6.4129 16.2045 6.69318 16.2045H17.2614C17.5416 16.2045 17.8105 16.0932 18.0086 15.895C18.2068 15.6968 18.3182 15.428 18.3182 15.1477C18.3182 14.8674 18.2068 14.5986 18.0086 14.4004C17.8105 14.2023 17.5416 14.0909 17.2614 14.0909H6.69318ZM5.63636 22.1932C5.63636 22.7765 6.10982 23.25 6.69318 23.25H11.625C11.9053 23.25 12.1741 23.1387 12.3723 22.9405C12.5705 22.7423 12.6818 22.4735 12.6818 22.1932C12.6818 21.9129 12.5705 21.6441 12.3723 21.4459C12.1741 21.2477 11.9053 21.1364 11.625 21.1364H6.69318C6.4129 21.1364 6.14409 21.2477 5.9459 21.4459C5.74771 21.6441 5.63636 21.9129 5.63636 22.1932Z"
                    fill="#0AA1DD"
                  />
                </svg>
              </Link>
              <div
                className="nav-item"
                style={{ textAlign: "end", marginTop: 5, marginLeft: 20 }}
              >
                <Link
                  to="/wallet-balance-history"
                  className="flex items-center text-base font-[500] hover:text-black"
                >
                  Balance History
                  <span className="text-[#0aa1dd] font-semibold">
                    <HiArrowTrendingUp size={20} />
                  </span>{" "}
                </Link>
              </div>
              <div
                className="nav-item"
                style={{ textAlign: "end", marginTop: 5, marginLeft: 20 }}
              >
                <h6>
                  Wallet balance
                  <br />{" "}
                  <span className="text-[#0aa1dd] font-semibold">
                    {data ?? "Loading..."}
                  </span>{" "}
                  KWD
                </h6>
              </div>
              <Link
                to="/notifications"
                className="nav-item dropdown notification_dropdown"
                onClick={handleNotificationClick}
              >
                {IsNewNotification ? (
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23.3333 19.8333H23.1187C23.2568 19.4597 23.3295 19.065 23.3333 18.6666V12.8333C23.3294 10.7663 22.6402 8.75902 21.3735 7.12565C20.1068 5.49228 18.3343 4.32508 16.3333 3.80679V3.49996C16.3333 2.88112 16.0875 2.28763 15.6499 1.85004C15.2123 1.41246 14.6188 1.16663 14 1.16663C13.3812 1.16663 12.7877 1.41246 12.3501 1.85004C11.9125 2.28763 11.6667 2.88112 11.6667 3.49996V3.80679C9.66574 4.32508 7.89317 5.49228 6.6265 7.12565C5.35983 8.75902 4.67058 10.7663 4.66667 12.8333V18.6666C4.67053 19.065 4.74316 19.4597 4.88133 19.8333H4.66667C4.35725 19.8333 4.0605 19.9562 3.84171 20.175C3.62292 20.3938 3.5 20.6905 3.5 21C3.5 21.3094 3.62292 21.6061 3.84171 21.8249C4.0605 22.0437 4.35725 22.1666 4.66667 22.1666H23.3333C23.6428 22.1666 23.9395 22.0437 24.1583 21.8249C24.3771 21.6061 24.5 21.3094 24.5 21C24.5 20.6905 24.3771 20.3938 24.1583 20.175C23.9395 19.9562 23.6428 19.8333 23.3333 19.8333Z"
                      fill="#ff0000" // Change the fill color to red for notifications
                    />
                    <path
                      d="M9.9819 24.5C10.3863 25.2088 10.971 25.7981 11.6766 26.2079C12.3823 26.6178 13.1838 26.8337 13.9999 26.8337C14.816 26.8337 15.6175 26.6178 16.3232 26.2079C17.0288 25.7981 17.6135 25.2088 18.0179 24.5H9.9819Z"
                      fill="#ff0000" // Change the fill color to red for notifications
                    />
                  </svg>
                ) : (
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23.3333 19.8333H23.1187C23.2568 19.4597 23.3295 19.065 23.3333 18.6666V12.8333C23.3294 10.7663 22.6402 8.75902 21.3735 7.12565C20.1068 5.49228 18.3343 4.32508 16.3333 3.80679V3.49996C16.3333 2.88112 16.0875 2.28763 15.6499 1.85004C15.2123 1.41246 14.6188 1.16663 14 1.16663C13.3812 1.16663 12.7877 1.41246 12.3501 1.85004C11.9125 2.28763 11.6667 2.88112 11.6667 3.49996V3.80679C9.66574 4.32508 7.89317 5.49228 6.6265 7.12565C5.35983 8.75902 4.67058 10.7663 4.66667 12.8333V18.6666C4.67053 19.065 4.74316 19.4597 4.88133 19.8333H4.66667C4.35725 19.8333 4.0605 19.9562 3.84171 20.175C3.62292 20.3938 3.5 20.6905 3.5 21C3.5 21.3094 3.62292 21.6061 3.84171 21.8249C4.0605 22.0437 4.35725 22.1666 4.66667 22.1666H23.3333C23.6428 22.1666 23.9395 22.0437 24.1583 21.8249C24.3771 21.6061 24.5 21.3094 24.5 21C24.5 20.6905 24.3771 20.3938 24.1583 20.175C23.9395 19.9562 23.6428 19.8333 23.3333 19.8333Z"
                      fill="#717579"
                    />
                    <path
                      d="M9.9819 24.5C10.3863 25.2088 10.971 25.7981 11.6766 26.2079C12.3823 26.6178 13.1838 26.8337 13.9999 26.8337C14.816 26.8337 15.6175 26.6178 16.3232 26.2079C17.0288 25.7981 17.6135 25.2088 18.0179 24.5H9.9819Z"
                      fill="#717579"
                    />
                  </svg>
                )}
              </Link>
              <Dropdown as="li" className="nav-item dropdown header-profile">
                <Dropdown.Toggle
                  variant=""
                  as="a"
                  className="nav-link i-false c-pointer"
                  role="button"
                  data-toggle="dropdown"
                >
                  <img
                    src={accessToken?.client?.logoUrl}
                    width={20}
                    alt=""
                    style={{ objectFit: "cover" }}
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu
                  align="right"
                  className="mt-3 dropdown-menu dropdown-menu-end"
                >
                  <div
                    className="dropdown-item ai-icon"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontWeight: "bold",
                    }}
                  >
                    <span className="ms-2">{username.user.fullname}</span>
                    <span
                      className="ms-2"
                      style={{
                        fontWeight: "normal",
                        textDecoration: "lowercase",
                      }}
                    >
                      ({username.user.role.replace("_", " ")})
                    </span>
                  </div>
                  <Link
                    to="/app-profile"
                    className="dropdown-item ai-icon"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <svg
                      id="icon-user1"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-primary me-1"
                      width={18}
                      height={18}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                      <circle cx={12} cy={7} r={4} />
                    </svg>
                    <span className="ms-2">Profile </span>
                  </Link>

                  {/* <LogoutPage /> */}
                </Dropdown.Menu>
              </Dropdown>

              {/* <li className="nav-item header-profile">
					<Link to={"#"} className="nav-link" role="button" data-bs-toggle="dropdown">
						<img src={profile} width="20" alt=""/>
					</Link>
				</li> */}
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
