import React, { createContext, useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Logout } from "../store/actions/AuthActions";
import { isAuthenticated } from "../store/selectors/AuthSelectors";
import swal from "sweetalert";

const LocalStorageContext = createContext();

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

const LocalStorageProvider = ({ children }) => {
  const localStorageKey = "userDetails"; // replace with your actual key
  let accessToken = JSON.parse(localStorage.getItem(localStorageKey));
  const [data, setData] = useState(null);
  const [ClientAccessibility, setClientAccessibility] = useState(null);
  const [WalletHistories, setWalletHistories] = useState([]);
  const [ExpiryTime, setExpiryTime] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  function onLogout() {
    dispatch(Logout(navigate));
    // window.location.reload();
  }

  const fetchData = () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL}users/${accessToken?.user?.id}/wallet`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (
          result.code === "ACC_DISABLED" ||
          result.message === "Your account has been disabled"
        ) {
          onLogout();
        } else if (
          result.message === "unauthorized" || result.message === "token expire" ||
          result.message === "unauthorized" || result.message === "token expire"
        ) {
          swal({
            title: "Session Expired!",
            text: `your session has expired! Kindly login again!`,
            icon: "warning",
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              onLogout();
            } else {
              onLogout();
            }
          });
        } else {
          setData(result?.wallet?.balance);
          setClientAccessibility(result.code);
          setWalletHistories(result?.wallet?.WalletHistories);
          setExpiryTime(result?.preference?.defaultInvoiceExpiryDuration);
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    fetchData(); // Fetch data initially
    const interval = setInterval(fetchData, 30000); // Fetch data every 30 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [data]);

  const updateWalletBalance = (newBalance) => {
    setData(0);
  };

  return (
    <LocalStorageContext.Provider
      value={{
        data,
        updateWalletBalance,
        WalletHistories,
        ClientAccessibility,
        ExpiryTime,
      }}
    >
      {children}
    </LocalStorageContext.Provider>
  );
};

export { LocalStorageContext, LocalStorageProvider };

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(
  connect(mapStateToProps)(LocalStorageContext, LocalStorageProvider)
);
